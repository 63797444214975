import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, updateDoc, doc, where, query, orderBy } from "firebase/firestore"
  
const StayAndPlay = () => {

  const usersCollectionRef = collection(db, "users")
  const [usersList, setUsersList] = useState([]);
  const [events, setEvents] = useState([]);
  const eventsCollectionRef = collection(db, "StayAndPlay");
  const [inputName, setInputName] = useState("");
  const [waitingName, setWaitingName] = useState("")
  let eventTime;
  const startOfDay = new Date();
  startOfDay.setUTCHours(0,0,0,0);
  let nameCheck="";
  let nameSwitch=false;
  const bannerCollectionRef = collection(db, "Banner");
  const [bannerData, setBannerData] = useState([]);

  useEffect(() =>{
    const getBanner = async () => {
      const qb = query(bannerCollectionRef);
      const banners = await getDocs(qb);
      setBannerData(banners.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    getBanner().then(function(){
      console.log("get banner ran");
      console.log(bannerData);
    })

  }, []);

  useEffect(() =>{
    const getUsers = async () => {
      const qw = query(usersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data()})))
    }

    getUsers();
  }, [])
  
  useEffect(() =>{

    const getEvents = async () =>{
      const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 
 

    getEvents().then(function(){
      console.log("get events function done")
    }); 

  }, []);

  const checkForAdd = async (id, index) =>{
    let tempData=[];
    const getEvents = async () =>{
      const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addParticipant(id, tempData[index].Attendees, index)
    }; 
    getEvents()
  }

  const checkForWaiting = async (id, index) =>{
    let tempData=[];
    const getEvents = async () =>{
      const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addWaitingList(id, tempData[index].Attendees, tempData[index].Waiting)
    }; 
    getEvents()
  }

  const addWaitingList = async (id, Attendees, Waiting) =>{
    const eventDoc = doc(db, "StayAndPlay", id)
    let shownName=""
    if (Attendees.length)
    if(inputName === ""){
      console.log("Error, no Input");
      document.getElementById(id+"text").style.display = "block";
    }else{
      for(let i=0; i<usersList.length; i++){
        nameCheck = usersList[i].email;
        if(nameCheck === inputName.toLowerCase()){
          console.log("yes, name is " + usersList[i].name);
          nameSwitch=true;
          shownName= usersList[i].name;
        }
      }
      if(!Attendees.includes(shownName) && !Waiting.includes(shownName)){
        if(nameSwitch===true){
          const newWaiting = {Waiting: [...Waiting, shownName]}
          await updateDoc(eventDoc, newWaiting);
          console.log("no error")
          document.getElementById(id+"text").style.display = "none";
          document.getElementById(id+"error").style.display = "none";
          document.getElementById(id+"duplicate").style.display = "none";
          document.getElementById(id+"success").style.display = "block";
          document.getElementById(id+"input").value = "";
          const getEvents = async () =>{
            const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
            const newData = await getDocs(q);
            setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
          };
          setInputName("");
          getEvents();
        }else{
          console.log("nameSwitch is false")
          document.getElementById(id+"error").style.display = "block";
        }
      }else{
        document.getElementById(id+"duplicate").style.display = "block";
      }
    }
  }


  const addParticipant = async (id, Attendees, index) =>{
    const eventDoc = doc(db, "StayAndPlay", id)
    let shownName=""
    if(events[index].Max_Participants > Attendees.length){
      if(inputName === ""){
        console.log("Error, no Input");
        document.getElementById(id+"text").style.display = "block";
      }else{
        for(let i=0; i<usersList.length; i++){
          nameCheck = usersList[i].email;
          if(nameCheck === inputName.toLowerCase()){
            console.log("yes, name is " + usersList[i].name);
            nameSwitch=true;
            shownName= usersList[i].name;
          }
        }
        if(!Attendees.includes(shownName)){
          if(nameSwitch===true){
            const newAttendees = {Attendees: [...Attendees, shownName]}
            await updateDoc(eventDoc, newAttendees);
            console.log("no error")
            document.getElementById(id+"text").style.display = "none";
            document.getElementById(id+"error").style.display = "none";
            document.getElementById(id+"duplicate").style.display = "none";
            document.getElementById(id+"success").style.display = "block";
            document.getElementById(id+"input").value = "";
            const getEvents = async () =>{
              const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
              const newData = await getDocs(q);
              setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
            };
            setInputName("");
            getEvents();
  
          }else{
            console.log("nameSwitch is false")
            document.getElementById(id+"error").style.display = "block";
          }
        }else{
          document.getElementById(id+"duplicate").style.display = "block";
        }
      }
    }else{
      document.getElementById(id+"checkFail").style.display = "block";
    }
    
  }


  return (
    <div class="marqueeDiv">
    <div class="mainContent">
      <h1>Upcoming Stay & Play Events</h1>
      <h4>To sign up for an upcoming event <br></br> enter your Email Address and hit "Sign Up"
      <br></br><br></br>If you need to cancel, contact Mike Baumgartner</h4>
      <ul class="eventList">
        {events?.map((event, index) => (
            <li key={event.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{event.Name}</h2>
                </div>
                <div class="eventHeader">
                  <h4 class="loc">{event.Location}</h4>
                  <h4>
                    From {new Date(event.Date.seconds * 1000 + event.Date.nanoseconds / 1000000).toDateString()} <br></br> 
                    To {new Date(event.EndDate.seconds * 1000 + event.EndDate.nanoseconds / 1000000).toDateString()}
                  </h4>

                </div>
                <div class="eventContent">
                  <section style={{width: "80%"}}>
                    <h4 style={{textAlign: "left"}}>Additional Notes:</h4>
                    <p>{event.Notes}</p>
                  </section>
                </div>
                <div class="eventContent">
                  <section>
                    <h2>Attendees:</h2>
                    <ol>
                      {event.Attendees.map((attendee, index)=>(
                        <li key={index}>
                          <p>{attendee}</p>
                        </li>
                      ))}
                    </ol>
                    <h2>Waiting List:</h2>
                    <ol>
                      {event.Waiting.map((person, index)=>(
                        <li key={index}>
                          <p>{person}</p>
                        </li>
                      ))}
                    </ol>
                  </section>
                  <div class="attendeeDiv">
                    <section>
                      <p class="attendeeInfo">Number of Attendees: {event.Attendees.length}</p>
                      <p class="attendeeInfo">Spots Remaining Available: {event.Max_Participants - event.Attendees.length}</p>
                      <p class="attendeeInfo">Number on Waiting List: {event.Waiting.length}</p>
                    </section>
                    <section>
                      {(() => {
                      if ((event.Max_Participants > event.Attendees.length)){
                          return (
                            <div class="inputDiv">
                              <input id={event.id+'input'} class="attendeeInput" placeholder="Email Address" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={event.id+'text'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Name</h3>
                                <h3 id={event.id+'duplicate'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <h5 id={event.id+'error'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Email entered was not recognized. <br></br>
                                If this was a mistake, contact Mike Baumgartner.</h5>
                              <button id={event.id} onClick={() =>{checkForAdd(event.id, index)}}>Sign Up</button>
                              <h3 id={event.id+'success'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                              <h3 id={event.id+'checkFail'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                            </div>
                          )
                        } else{
                        return(
                          <div>
                            <h3 style={{color: 'red', fontSize: '2em'}}>Event is Full</h3>
                            <h3>Join the Waiting List</h3>
                            <div class="inputDiv">
                              <input id={event.id+'input'} class="attendeeInput" placeholder="Email Address" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={event.id+'text'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Name</h3>
                                <h3 id={event.id+'duplicate'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <h5 id={event.id+'error'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Email entered was not recognized. <br></br>
                                If this was a mistake, contact Mike Baumgartner.</h5>
                              <button id={event.id} onClick={() =>{checkForWaiting(event.id, index)}}>Join Waiting List</button>
                              <h3 id={event.id+'success'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                              <h3 id={event.id+'checkFail'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                            </div>
                          </div>
                          )
                        }
                      })()}
                    </section>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>

    </div>
    </div>
  );
};
  
export default StayAndPlay;