import React, { useState, useRef, useSyncExternalStore } from "react";
import emailjs from '@emailjs/browser'
import { useEffect } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, updateDoc, addDoc, doc, where, query, orderBy, deleteDoc, arrayRemove } from "firebase/firestore"
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { usersData } from "./user_info";
import "react-datepicker/dist/react-datepicker.css";

  
const Add_Event = () => {
    const eventsCollectionRef = collection(db, "Events");
    const stayPlayCollectionRef = collection(db, "StayAndPlay")
    const usersCollectionRef = collection(db, "users");
    const bannerCollectionRef = collection(db, "Banner");
    const charCollectionRef = collection(db, "CharityEvents");
    let bannerAcc = false;
    let userAcc = false;
    let editAcc = false;
    let addAcc = false;
    let addSPAcc = false;
    let editSPAcc = false;
    let charityAcc = false;
    let charityAddAcc = false;
    const [bannerMessage, setBannerMessage] = useState("");
    const [bannerData, setBannerData] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [editEmail, setEditEmail] = useState("");
    const [editUser, setEditUser] = useState("");
    const [eventName, setEventName] = useState("");
    const [location, setLocation] = useState("");
    const [maxNumber, setMaxNumber] = useState(0);
    const [attendees, setAttendees] = useState([]);
    const [waitingList, setWaitingList] = useState([]);
    const [waitingName, setWaitingName] = useState("");
    const [notes, setNotes] = useState("");
    const [date, setDate] = useState(new Date());    
    const [endDate, setEndDate] = useState(new Date());    
    const [events, setEvents] = useState([]);
    const [SPEvents, setSPEvents] = useState([]);
    const [charEvents, setCharEvents] = useState([]);
    const [charityName, setCharityName] = useState("");
    const [charityLoc, setCharityLoc] = useState("");
    const [charityDate, setCharityDate] = useState(new Date());
    const [charityInfo, setCharityInfo] = useState("");
    const [charityLink, setCharityLink] = useState("")
    const [inputName, setInputName] = useState("");
    const [editName, setEditName] = useState("");
    const [editLoc, setEditLoc] = useState("");
    const [editDate, setEditDate] = useState(new Date());
    const [editEndDate, setEditEndDate] = useState(new Date());
    const [editPartNum, setEditPartNum] = useState(0);
    const [editNotes, setEditNotes] = useState("");
    const [editCharName, setEditCharName] = useState("");
    const [editCharLoc, setEditCharLoc] = useState("");
    const [editCharDate, setEditCharDate] = useState(new Date());
    const [editCharInfo, setEditCharInfo] = useState("");
    const [editCharLink, setEditCharLink] = useState("")
    const [textBox, setTextBox] = useState("")
    const [SPTextBox, setSPTextBox] = useState("")
    const [emailList, setEmailList] = useState(["mikebaumgartner1024@gmail.com,"])
    let emailHolder = [];
    let eventTime;
    let valueCheck;
    const startOfDay = new Date();
    startOfDay.setUTCHours(0,0,0,0);
    let nameCheck="";
    let nameSwitch=false;

    const editBanner = async (id) => {
      console.log("edit banner")
      const bannerDoc = doc(db, "Banner", id);
      await updateDoc(bannerDoc, {
          message: bannerMessage
      })
      window.location.reload();
    }

    const addUser = async () => {
      let testname
      let testemail
      if(userName !== "" && userEmail !== ""){
        document.getElementById("userError").style.display = "none";
        document.getElementById("usersuccess").style.display = "block";
        // for(let i=0; i<usersData.length; i++){
        //   testname=usersData[i].name;
        //   testemail=usersData[i].email;
        //   await addDoc(usersCollectionRef, {name: testname, email: testemail})
        // }
        await addDoc(usersCollectionRef, {name: userName, email: userEmail, mailingList: false})
      }else{
        document.getElementById("userError").style.display = "block";
        document.getElementById("usersuccess").style.display = "none";
      }
    }

    useEffect(() =>{
      setTextBox("A new Daily event, "+eventName +", has been scheduled for "+date+" at "+location+". To sign up for this event or any other live event, click the link below.")
    }, [eventName, date, location])

    useEffect(() =>{
      setSPTextBox("A new Stay & Play event, "+eventName +", has been scheduled for "+date+" at "+location+". To sign up for this event or any other live event, click the link below.")
    }, [eventName, date, location])

    useEffect(() =>{
      const getCharEvents = async () =>{
        const q = query(charCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
        const newCharData = await getDocs(q);
        setCharEvents(newCharData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      }; 
  
      getCharEvents().then(function(){
        console.log("get events function done")
      }); 
  
    }, []);

    useEffect(() => {
      for(let i=0; i<usersList.length; i++){
        valueCheck = usersList[i].mailingList
        if(valueCheck == true){
          emailHolder.push(usersList[i].email.toString())
        }
      }
      setEmailList(emailHolder)
    }, [usersList])

    const form = useRef();

    const spform = useRef();


    const sendEmail = () => {

      emailjs
        .sendForm('service_txm4wmb', 'template_bj1f1ug', form.current, {
          publicKey: 'WMDbWLC9oK18Izvg7',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };

    const sendSPEmail = () => {

      emailjs
        .sendForm('service_txm4wmb', 'template_c4x7nwg', spform.current, {
          publicKey: 'WMDbWLC9oK18Izvg7',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };

    const createEvent = async () =>{
        if(eventName !== "" && location !== "" && maxNumber !== 0){
            document.getElementById("fieldError").style.display = "none"
            sendEmail();
            await addDoc(eventsCollectionRef, { Attendees: [attendees], Date: date, Location: location, Max_Participants: maxNumber, Name: eventName, Notes: notes, Waiting: []})
   
            window.location.reload();
        }else{
            console.log("Fields not completed")
            document.getElementById("fieldError").style.display = "block";
        }
    }

    const createSPEvent = async () =>{
      if(eventName !== "" && location !== "" && maxNumber !== 0){
          document.getElementById("fieldError").style.display = "none"
          sendSPEmail();
          await addDoc(stayPlayCollectionRef, { Attendees: [attendees], Date: date, EndDate: endDate, Location: location, Max_Participants: maxNumber, Name: eventName, Notes: notes, Waiting: []})
 
          window.location.reload();
      }else{
          console.log("Fields not completed")
          document.getElementById("fieldError").style.display = "block";
      }
  }

    const createCharityEvent = async () =>{
      if(charityName !== "" && charityLoc !== "" && charityInfo !== "" && charityLink != ""){
          document.getElementById("fieldError").style.display = "none"
          await addDoc(charCollectionRef, {Name: charityName, Location: charityLoc, Info: charityInfo, Link: charityLink, Date: charityDate})
 
          window.location.reload();
      }else{
          console.log("Fields not completed")
          document.getElementById("fieldError").style.display = "block";
      }

  }

    const deleteAttendee = async (id, attendee, Attendees, Waiting) =>{
        console.log(id);
        const eventDoc = doc(db, "Events", id);
        await updateDoc(eventDoc, {
            Attendees: arrayRemove(attendee)
        })
        window.location.reload();
    }

    const deleteWaiting = async (id, attendee) =>{
      console.log(id);
      const eventDoc = doc(db, "Events", id);
      await updateDoc(eventDoc, {
          Waiting: arrayRemove(attendee)
      })
      window.location.reload();
  }

  const moveWaiting = async (id, attendee, Attendees) => {
    const eventDoc = doc(db, "Events", id);
    const newAttendees = {Attendees: [...Attendees, attendee]}
    await updateDoc(eventDoc, newAttendees);
    await updateDoc(eventDoc, {
      Waiting: arrayRemove(attendee)
    })
    window.location.reload();
  }

    const updateName = async (id) =>{
      const eventDoc = doc(db, "Events", id);
      if(editName !== ""){
        await updateDoc(eventDoc, {
          Name: editName
        })
        window.location.reload();
      }else{
        console.log("Edit Name Field Empty")
      }

    }

    const updateLocation = async (id) =>{
      const eventDoc = doc(db, "Events", id);
      if(editLoc !== ""){
        await updateDoc(eventDoc, {
          Location: editLoc
        })
        console.log("update location ran")
        window.location.reload();
      }
    }

    const updateDate = async (id) =>{
      const eventDoc = doc(db, "Events", id);
        await updateDoc(eventDoc, {
          Date: editDate
        })
        console.log("update date ran")
        window.location.reload();
    }

    const updateMaxNum = async (id) =>{
      const eventDoc = doc(db, "Events", id);
      if(editPartNum!=="0"){
        await updateDoc(eventDoc, {
          Max_Participants: editPartNum
        })
        console.log("update max number ran")
        window.location.reload();
      }else{
        console.log("Field Empty")
      }

    }

    const updateNotes = async (id) =>{
      const eventDoc = doc(db, "Events", id);
      await updateDoc(eventDoc, {
        Notes: editNotes
      })
      window.location.reload();
    }

    const deleteSPWaiting = async (id, attendee) =>{
      console.log(id);
      const eventDoc = doc(db, "StayAndPlay", id);
      await updateDoc(eventDoc, {
          Waiting: arrayRemove(attendee)
      })
      window.location.reload();
  }

  const moveSPWaiting = async (id, attendee, Attendees) => {
    const eventDoc = doc(db, "StayAndPlay", id);
    const newAttendees = {Attendees: [...Attendees, attendee]}
    await updateDoc(eventDoc, newAttendees);
    await updateDoc(eventDoc, {
      Waiting: arrayRemove(attendee)
    })
    window.location.reload();
  }

    const updateSPName = async (id) =>{
      const eventDoc = doc(db, "StayAndPlay", id);
      if(editName !== ""){
        await updateDoc(eventDoc, {
          Name: editName
        })
        window.location.reload();
      }else{
        console.log("Edit Name Field Empty")
      }

    }

    const updateSPLocation = async (id) =>{
      const eventDoc = doc(db, "StayAndPlay", id);
      if(editLoc !== ""){
        await updateDoc(eventDoc, {
          Location: editLoc
        })
        console.log("update location ran")
        window.location.reload();
      }
    }

    const updateSPDate = async (id) =>{
      const eventDoc = doc(db, "StayAndPlay", id);
        await updateDoc(eventDoc, {
          Date: editDate
        })
        console.log("update date ran")
        window.location.reload();
    }

    const updateSPEndDate = async (id) =>{
      const eventDoc = doc(db, "StayAndPlay", id);
        await updateDoc(eventDoc, {
          EndDate: editEndDate
        })
        console.log("update date ran")
        window.location.reload();
    }

    const updateSPMaxNum = async (id) =>{
      const eventDoc = doc(db, "StayAndPlay", id);
      if(editPartNum!=="0"){
        await updateDoc(eventDoc, {
          Max_Participants: editPartNum
        })
        console.log("update max number ran")
        window.location.reload();
      }else{
        console.log("Field Empty")
      }

    }

    const updateSPNotes = async (id) =>{
      const eventDoc = doc(db, "StayAndPlay", id);
      await updateDoc(eventDoc, {
        Notes: editNotes
      })
      window.location.reload();
    }

    const checkSPForRemove = async (id, attendee) =>{
      const getSPEvents = async () =>{
        const q = query(stayPlayCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
        const newData = await getDocs(q);
        setSPEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
        deleteSPAttendee(id, attendee)
      }; 
      getSPEvents()
    }

    const deleteSPAttendee = async (id, attendee, Attendees, Waiting) =>{
      console.log(id);
      const eventDoc = doc(db, "StayAndPlay", id);
      await updateDoc(eventDoc, {
          Attendees: arrayRemove(attendee)
      })
      window.location.reload();
  }

  const checkSPForAdd = async (id, index) =>{
    let tempData=[];
    const getSPEvents = async () =>{
      const q = query(stayPlayCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setSPEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addParticipant(id, tempData[index].Attendees, index)
    }; 
    getSPEvents()
  }

  const addSPParticipant = async (id, Attendees, index) =>{
    const eventDoc = doc(db, "StayAndPlay", id)
    let shownName=""
    if(events[index].Max_Participants > Attendees.length){
      if(inputName === ""){
        console.log("Error, no Input");
        document.getElementById(id+"text").style.display = "block";
      }else{
        shownName=inputName
        if(!Attendees.includes(shownName)){
            const newAttendees = {Attendees: [...Attendees, shownName]}
            await updateDoc(eventDoc, newAttendees);
            console.log("no error")
            document.getElementById(id+"text").style.display = "none";
            document.getElementById(id+"error").style.display = "none";
            document.getElementById(id+"duplicate").style.display = "none";
            document.getElementById(id+"success").style.display = "block";
            document.getElementById(id+"input").value = "";
            const getSPEvents = async () =>{
              const q = query(stayPlayCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
              const newData = await getDocs(q);
              setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
            };
            setInputName("");
            getSPEvents();
        }else{
          document.getElementById(id+"duplicate").style.display = "block";
        }
      }
    }else{
      document.getElementById(id+"checkFail").style.display = "block";
    }
  }

  const deleteSPEvent = async (id) => {
    const eventDoc = doc(db, "StayAndPlay", id);
    await deleteDoc(eventDoc);
    window.location.reload();
  }

    const updateCharName = async (id) =>{
      const charityDoc = doc(db, "CharityEvents", id);
      if(editCharName !== ""){
        await updateDoc(charityDoc, {
          Name: editCharName
        })
        window.location.reload();
      }else{
        console.log("Edit Name Field Empty")
      }

    }

    const updateCharLocation = async (id) =>{
      const charityDoc = doc(db, "CharityEvents", id);
      if(editCharLoc !== ""){
        await updateDoc(charityDoc, {
          Location: editCharLoc
        })
        console.log("update location ran")
        window.location.reload();
      }
    }

    const updateCharDate = async (id) =>{
      const charityDoc = doc(db, "CharityEvents", id);
        await updateDoc(charityDoc, {
          Date: editCharDate
        })
        console.log("update date ran")
        window.location.reload();
    }

    const updateCharInfo = async (id) =>{
      const charityDoc = doc(db, "CharityEvents", id);
        await updateDoc(charityDoc, {
          Info: editCharInfo
        })
        console.log("update date ran")
        window.location.reload();
    }

    const updateCharLink = async (id) =>{
      const charityDoc = doc(db, "CharityEvents", id);
        await updateDoc(charityDoc, {
          Link: editCharLink
        })
        console.log("update date ran")
        window.location.reload();
    }

    const deleteEvent = async (id) => {
      const eventDoc = doc(db, "Events", id);
      await deleteDoc(eventDoc);
      window.location.reload();
    }

    const deleteCharEvent = async (id) => {
      const eventDoc = doc(db, "CharityEvents", id);
      await deleteDoc(eventDoc);
      window.location.reload();
    }

    const deleteUser = async (id) => {
      const eventDoc = doc(db, "users", id);
      await deleteDoc(eventDoc);
      window.location.reload();
    }

    const updateUser = async (id) => {
      if(editUser != ""){
        const userdoc = doc(db, "users", id);
        await updateDoc(userdoc, {
          name: editUser
        })
      }
    }

    const updateEmail = async (id) => {
      if(editEmail != ""){
        const userdoc = doc(db, "users", id);
        await updateDoc(userdoc, {
          email: editEmail
        })
      }
    }

    const updateMailingList = async (id, mailingListVal) => {
        const userdoc = doc(db, "users", id);
        await updateDoc(userdoc, {
          mailingList: !mailingListVal
        })
    }

  useEffect(() =>{

    const getEvents = async () =>{
      const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 

    const getSPEvents = async () =>{
      const q = query(stayPlayCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setSPEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 
  
    const getUsers = async () => {
      const qw = query(usersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data(), id: doc.id})))
    };

    const getBanner = async () => {
      const qb = query(bannerCollectionRef);
      const banners = await getDocs(qb);
      setBannerData(banners.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    getEvents().then(function(){
      console.log("get events function done");
      console.log(events);
    }); 

    getSPEvents().then(function(){
      console.log("get SP events function done");
      console.log(events);
    }); 

    getUsers().then(function(){
      console.log("get users ran");
      console.log(usersList);
    })

    getBanner().then(function(){
      console.log("get banner ran");
      console.log(bannerData);
    })

  }, []);

  
  const checkForAdd = async (id, index) =>{
    let tempData=[];
    const getEvents = async () =>{
      const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addParticipant(id, tempData[index].Attendees, index)
    }; 
    getEvents()
  }

  const checkForRemove = async (id, attendee) =>{
    const getEvents = async () =>{
      const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      deleteAttendee(id, attendee)
    }; 
    getEvents()
  }


  const addParticipant = async (id, Attendees, index) =>{
    const eventDoc = doc(db, "Events", id)
    let shownName=""
    if(events[index].Max_Participants > Attendees.length){
      if(inputName === ""){
        console.log("Error, no Input");
        document.getElementById(id+"text").style.display = "block";
      }else{
        shownName=inputName
        if(!Attendees.includes(shownName)){
            const newAttendees = {Attendees: [...Attendees, shownName]}
            await updateDoc(eventDoc, newAttendees);
            console.log("no error")
            document.getElementById(id+"text").style.display = "none";
            document.getElementById(id+"error").style.display = "none";
            document.getElementById(id+"duplicate").style.display = "none";
            document.getElementById(id+"success").style.display = "block";
            document.getElementById(id+"input").value = "";
            const getEvents = async () =>{
              const q = query(eventsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
              const newData = await getDocs(q);
              setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
            };
            setInputName("");
            getEvents();
        }else{
          document.getElementById(id+"duplicate").style.display = "block";
        }
      }
    }else{
      document.getElementById(id+"checkFail").style.display = "block";
    }
  }

  const bannerAccButton = () => {
    bannerAcc = !bannerAcc
    if(bannerAcc){
      document.getElementById("bannerAccordion").style.display = "block"
    }else{
      document.getElementById("bannerAccordion").style.display = "none"
    }
  }

  const charityAccButton = () => {
    charityAcc = !charityAcc
    if(charityAcc){
      document.getElementById("charityAccordion").style.display = "block"
    }else{
      document.getElementById("charityAccordion").style.display = "none"
    }
  }

  const charityAddAccButton = () => {
    charityAddAcc = !charityAddAcc
    if(charityAddAcc){
      document.getElementById("charityAddAccordion").style.display = "block"
    }else{
      document.getElementById("charityAddAccordion").style.display = "none"
    }
  }

  const userAccButton = () => {
    userAcc = !userAcc
    if(userAcc){
      document.getElementById("usersAccordion").style.display = "block"
    }else{
      document.getElementById("usersAccordion").style.display = "none"
    }
  }

  const addAccButton = () => {
    addAcc = !addAcc
    if(addAcc){
      document.getElementById("addAccordion").style.display = "block"
    }else{
      document.getElementById("addAccordion").style.display = "none"
    }
  }

  const addSPAccButton = () => {
    addSPAcc = !addSPAcc
    if(addSPAcc){
      document.getElementById("addSPAccordion").style.display = "block"
    }else{
      document.getElementById("addSPAccordion").style.display = "none"
    }
  }

  const editSPAccButton = () => {
    editSPAcc = !editSPAcc
    if(editSPAcc){
      document.getElementById("editSPAccordion").style.display = "block"
    }else{
      document.getElementById("editSPAccordion").style.display = "none"
    }
  }

  const editAccButton = () => {
    editAcc = !editAcc
    if(editAcc){
      document.getElementById("editAccordion").style.display = "block"
    }else{
      document.getElementById("editAccordion").style.display = "none"
    }
  }

  return (
    <div class="marqueeDiv">
      <marquee class="bannerMessage" >{bannerData[0]?.message}</marquee>
    <div class="mainContent">
      <div class="accButtonDiv">
      <button class="accordionButton" onClick={bannerAccButton}>Edit Banner</button>
      <div class="accordion" id="bannerAccordion" style={{display: "none"}}>
        <div class="addEventdiv" style={{width:"80%"}}>
          <ul style={{listStyle: "none"}}>
            {bannerData.map((banner) => (
              <li key={banner.id}>
                Current Message: {banner.message} <br></br>
                <input class="addEvent" placeholder="Edit Banner Message" onChange={(event)=> {setBannerMessage(event.target.value)}}/>
                <button onClick={() => {editBanner(banner.id)}} style={{marginBottom: "30px"}}> Edit Banner </button>
                <h3 id={'usersuccess'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User Added!</h3>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button class="accordionButton" onClick={userAccButton}>Add/Edit Users</button>
      <div class="accordion" id="usersAccordion" style={{display: "none"}}>
        <h1>Add Users</h1>
        <div class="addEventdiv" style={{width:"80%"}}>
          <input class="addEvent" placeholder="user name" onChange={(event)=> {setUserName(event.target.value)}}/>
          <input class="addEvent" placeholder="user email" onChange={(event)=> {setUserEmail(event.target.value.toLowerCase())}}/>
          <h2 id="userError" style={{display: "none", color: "red"}}>Name and Email need a value</h2>
          <button onClick={addUser}> Add User </button>
          <h3 id={'usersuccess'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User Added!</h3>
          <ul class="userList">
            {usersList.map((user) =>(
              <li key={user.id}>
                {user.name} : {user.email}<button onClick={() =>{deleteUser(user.id)}} style={{backgroundColor: "red", color: "white"}}>Delete User</button>
                <br></br>
                <input placeholder="Edit Name" onChange={(event)=>{setEditUser(event.target.value)}}></input>
                <button onClick={()=>{updateUser(user.id)}}>Edit Name</button>
                <br></br>
                <input placeholder="Edit Email" onChange={(event)=>{setEditEmail(event.target.value)}}></input>
                <button onClick={()=>{updateEmail(user.id)}}>Edit Email</button>
                <br></br>
                On mailing list? : {user.mailingList.toString()}
                <button onClick={()=>{updateMailingList(user.id, user.mailingList)}}>Edit Mailing List</button>
              </li>
            ))}
          </ul>

        </div>
      </div>
      <button class="accordionButton" onClick={addAccButton}>Add Events</button>
      <div class="accordion" id="addAccordion" style={{display: "none"}}>
        <h1>Add Events</h1>
        <div class="addEventdiv">
            <input class="addEvent" placeholder="Event Name" onChange={(event)=>{
                setEventName(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" placeholder="Location" onChange={(event)=>{
                setLocation(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" type="number"placeholder="Max Participants" onChange={(event)=>{
                setMaxNumber(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" type="array" placeholder="Attendees" onChange={(event)=>{
                setAttendees(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" placeholder="Notes" onChange={(event)=>{
                setNotes(event.target.value);
            }}/>
            <br></br>
            <DatePicker 
                showTimeInput
                dateFormat="MMMM d, yyyy h:mmaa"
            selected={date} onChange={date=> setDate(date)} />
            <br></br>
            <button onClick={createEvent}>Add Event</button>
            <form ref={form} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
              <label>Email List</label>
              <input type="email" name="user_email" multiple="true" value={emailList} style={{width: "90%", height: "100px", margin: "0 auto"}}/>
              <label>Message</label>
              <textarea name="message" value={textBox} style={{width: "90%", height: "100px", margin: "0 auto"}} onChange={(event)=>{setTextBox(event.target.value)}}/>
            </form>
            <h2 id="fieldError" style={{display: "none", color: "red"}}>EVENT NAME, LOCATION, MAX PARTICIPANTS AND ATTENDEES MUST BE FILLED OUT</h2>
        </div>
      </div>
    <button class="accordionButton" onClick={editAccButton}>Edit Events</button>
    <div class="accordion" id="editAccordion" style={{display: "none"}}>
      <h1>Upcoming Events</h1>
      <h4>To sign up for an upcoming event <br></br> enter your Name and hit "Sign Up"
      <br></br><br></br>If you need to cancel, contact Mike Baumgartner</h4>
      <ul>
        {events?.map((event, index) => (
            <li key={event.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{event.Name}</h2>
                  <input class="eventEdit" placeholder="Edit Name" onChange={(e) =>{setEditName(e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateName(event.id)}}>Edit Event Name</button>
                </div>
                <div class="eventHeader">
                  <h4 >{event.Location}</h4>
                  <input class="eventEdit" placeholder="Edit Location" onChange={(e) =>{setEditLoc(e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateLocation(event.id)}}>Edit Event Location</button>
                  <h4>
                    {new Date(event.Date.seconds * 1000 + event.Date.nanoseconds / 1000000).toDateString()}<br></br>
                    {eventTime = event.Date.toDate().toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}
                  </h4>
                  <DatePicker 
                    showTimeInput
                    dateFormat="MMMM d, yyyy h:mmaa"
                    selected={editDate} onChange={date=> setEditDate(date)} />
                  <button class="editButton" onClick={()=>{updateDate(event.id)}}>Edit Event Date</button>
                  {/* {console.log(event.Date.toDate().toISOString())}
                  {console.log(currentDate)} */}
                  {/* {console.log(fixedTime = new Date(event.Date.seconds * 1000 + event.Date.nanoseconds / 1000000))} */}
                  {/* {firebaseTime = new Date(event.Date.seconds * 1000 + event.Date.nanoseconds / 1000000)} */}
                  {/* {console.log(firebaseTime.toDateString())} */}

                  {/* <h4>{event.Date}</h4> */}
                </div>
                <div class="eventContent">
                  <section>
                    <h2>Attendees:</h2>
                    <ol>
                      {event.Attendees.map((attendee, index)=>(
                        <li key={index} style={{"display":"flex", "justifyContent":"space-between", "paddingRight":"20px"}}>
                          <p>{attendee}</p>
                          <button class="deleteUser" onClick={() =>{checkForRemove(event.id, attendee)}}>Delete Attendee</button>
                        </li>
                      ))}
                    </ol>
                    <h2>Waiting List:</h2>
                    <ol>
                      {event.Waiting.map((person, index)=>(
                        <li key={index}>
                          <p>{person}</p>
                          <button class="deleteUser" onClick={() =>{deleteWaiting(event.id, person)}}>Delete Waiting</button>
                          <button class="deleteUser" onClick={() =>{moveWaiting(event.id, person, event.Attendees, index)}}>Move to Attendees</button>

                        </li>
                      ))}
                    </ol>
                  </section>
                  <div class="attendeeDiv">
                    <section>
                      <p class="attendeeInfo">Number of Attendees: {event.Attendees.length}</p>
                      <p class="attendeeInfo">Spots Available: {event.Max_Participants - event.Attendees.length}</p>
                      <div>
                        <p>Max Participants: {event.Max_Participants}</p>
                        <input type="number" class="eventEdit" placeholder="Edit Max Participants" onChange={(e) =>{setEditPartNum(e.target.value)}}></input>
                        <button class="editButton" onClick={()=>{updateMaxNum(event.id)}}>Edit Max Participants Number</button>
                      </div>
                    </section>
                    <section>
                      {(() => {
                      if ((event.Max_Participants > event.Attendees.length)){
                          return (
                            <div class="inputDiv">
                              <input id={event.id+'input'} class="attendeeInput" placeholder="Email Address" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={event.id+'text'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Name</h3>
                                <h3 id={event.id+'duplicate'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <h5 id={event.id+'error'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Email entered was not recognized. <br></br>
                                If this was a mistake, contact Mike Baumgartner.</h5>
                              <button id={event.id} onClick={() =>{checkForAdd(event.id, index)}}>Sign Up</button>
                              <h3 id={event.id+'success'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                            </div>
                          )
                        } else{
                        return(
                          <h3 style={{color: 'red', fontSize: '2em'}}>Event is Full</h3>
                          )
                        }
                      })()}
                    </section>
                  </div>
                </div>
                <div class="notesDiv">
                  <h4 class="notesText">Additional Notes:<br></br>{event.Notes}</h4>
                  <input style={{margin: "0 auto", marginBottom: "10px"}} class="eventEdit" placeholder="Edit Notes" onChange={(e)=>{setEditNotes(e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateNotes(event.id)}}>Edit Notes</button>
                  <button id={event.id+'deleteEvent'} class="deleteEvent" onClick={() =>{deleteEvent(event.id)}}>Delete Event</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
    </div>
    <button class="accordionButton" onClick={addSPAccButton}>Add Stay&Play Events</button>
      <div class="accordion" id="addSPAccordion" style={{display: "none"}}>
        <h1>Add Stay&Play Events</h1>
        <div class="addEventdiv">
            <input class="addEvent" placeholder="Event Name" onChange={(event)=>{
                setEventName(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" placeholder="Location" onChange={(event)=>{
                setLocation(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" type="number"placeholder="Max Participants" onChange={(event)=>{
                setMaxNumber(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" type="array" placeholder="Attendees" onChange={(event)=>{
                setAttendees(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" placeholder="Notes" onChange={(event)=>{
                setNotes(event.target.value);
            }}/>
            <br></br>
            <h4>Start Date:</h4>
            <DatePicker 
                showTimeInput
                dateFormat="MMMM d, yyyy h:mmaa"
            selected={date} onChange={date=> setDate(date)} />
            <br></br>
            <br></br>
            <h4>End Date:</h4>
            <DatePicker 
                showTimeInput
                dateFormat="MMMM d, yyyy h:mmaa"
            selected={endDate} onChange={endDate=> setEndDate(endDate)} />
            <br></br>
            <button onClick={createSPEvent}>Add S&P Event</button>
            <form ref={spform} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
              <label>Email List</label>
              <input type="email" name="user_email" multiple="true" value={emailList} style={{width: "90%", height: "100px", margin: "0 auto"}}/>
              <label>Message</label>
              <textarea name="message" value={SPTextBox} style={{width: "90%", height: "100px", margin: "0 auto"}} onChange={(event)=>{setSPTextBox(event.target.value)}}/>
            </form>
            <h2 id="fieldError" style={{display: "none", color: "red"}}>EVENT NAME, LOCATION, MAX PARTICIPANTS AND ATTENDEES MUST BE FILLED OUT</h2>
        </div>
      </div>
      <button class="accordionButton" onClick={editSPAccButton}>Edit Stay&Play Events</button>
    <div class="accordion" id="editSPAccordion" style={{display: "none"}}>
      <h1>Upcoming S&P Events</h1>
      <h4>To sign up for an upcoming event <br></br> enter your Name and hit "Sign Up"
      <br></br><br></br>If you need to cancel, contact Mike Baumgartner</h4>
      <ul>
        {SPEvents?.map((event, index) => (
            <li key={event.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{event.Name}</h2>
                  <input class="eventEdit" placeholder="Edit Name" onChange={(e) =>{setEditName(e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateSPName(event.id)}}>Edit Event Name</button>
                </div>
                <div class="eventHeader">
                  <h4 >{event.Location}</h4>
                  <input class="eventEdit" placeholder="Edit Location" onChange={(e) =>{setEditLoc(e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateSPLocation(event.id)}}>Edit Event Location</button>
                  <h4>
                    From {new Date(event.Date.seconds * 1000 + event.Date.nanoseconds / 1000000).toDateString()} {eventTime = event.Date.toDate().toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}<br></br> 
                    To {new Date(event.EndDate.seconds * 1000 + event.EndDate.nanoseconds / 1000000).toDateString()} {eventTime = event.EndDate.toDate().toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}
                  </h4>
                  <DatePicker 
                    showTimeInput
                    dateFormat="MMMM d, yyyy h:mmaa"
                    selected={editDate} onChange={date=> setEditDate(date)} />
                  <button class="editButton" onClick={()=>{updateSPDate(event.id)}}>Edit Event Start Date</button>
                  <DatePicker 
                    showTimeInput
                    dateFormat="MMMM d, yyyy h:mmaa"
                    selected={editEndDate} onChange={date=> setEditEndDate(date)} />
                  <button class="editButton" onClick={()=>{updateSPEndDate(event.id)}}>Edit Event End Date</button>
                </div>
                <div class="eventContent">
                  <section>
                    <h2>Attendees:</h2>
                    <ol>
                      {event.Attendees.map((attendee, index)=>(
                        <li key={index} style={{"display":"flex", "justifyContent":"space-between", "paddingRight":"20px"}}>
                          <p>{attendee}</p>
                          <button class="deleteUser" onClick={() =>{checkSPForRemove(event.id, attendee)}}>Delete Attendee</button>
                        </li>
                      ))}
                    </ol>
                    <h2>Waiting List:</h2>
                    <ol>
                      {event.Waiting.map((person, index)=>(
                        <li key={index}>
                          <p>{person}</p>
                          <button class="deleteUser" onClick={() =>{deleteSPWaiting(event.id, person)}}>Delete Waiting</button>
                          <button class="deleteUser" onClick={() =>{moveSPWaiting(event.id, person, event.Attendees, index)}}>Move to Attendees</button>

                        </li>
                      ))}
                    </ol>
                  </section>
                  <div class="attendeeDiv">
                    <section>
                      <p class="attendeeInfo">Number of Attendees: {event.Attendees.length}</p>
                      <p class="attendeeInfo">Spots Available: {event.Max_Participants - event.Attendees.length}</p>
                      <div>
                        <p>Max Participants: {event.Max_Participants}</p>
                        <input type="number" class="eventEdit" placeholder="Edit Max Participants" onChange={(e) =>{setEditPartNum(e.target.value)}}></input>
                        <button class="editButton" onClick={()=>{updateSPMaxNum(event.id)}}>Edit Max Participants Number</button>
                      </div>
                    </section>
                    <section>
                      {(() => {
                      if ((event.Max_Participants > event.Attendees.length)){
                          return (
                            <div class="inputDiv">
                              <input id={event.id+'input'} class="attendeeInput" placeholder="Email Address" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={event.id+'text'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Name</h3>
                                <h3 id={event.id+'duplicate'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <h5 id={event.id+'error'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Email entered was not recognized. <br></br>
                                If this was a mistake, contact Mike Baumgartner.</h5>
                              <button id={event.id} onClick={() =>{checkSPForAdd(event.id, index)}}>Sign Up</button>
                              <h3 id={event.id+'success'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                            </div>
                          )
                        } else{
                        return(
                          <h3 style={{color: 'red', fontSize: '2em'}}>Event is Full</h3>
                          )
                        }
                      })()}
                    </section>
                  </div>
                </div>
                <div class="notesDiv">
                  <h4 class="notesText">Additional Notes:<br></br>{event.Notes}</h4>
                  <input style={{margin: "0 auto", marginBottom: "10px"}} class="eventEdit" placeholder="Edit Notes" onChange={(e)=>{setEditNotes(e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateSPNotes(event.id)}}>Edit Notes</button>
                  <button id={event.id+'deleteEvent'} class="deleteEvent" onClick={() =>{deleteSPEvent(event.id)}}>Delete Event</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
    </div>
    <button class="accordionButton" onClick={charityAddAccButton}>Add Charity Events</button>
      <div class="accordion" id="charityAddAccordion" style={{display: "none"}}>
        <div class="addEventdiv">
          <input class="addEvent" placeholder="Name" onChange={(event)=>{
              setCharityName(event.target.value);
          }}/>
          <br></br>
          <input class="addEvent" placeholder="Location" onChange={(event)=>{
              setCharityLoc(event.target.value);
          }}/>
          <br></br>
          <input class="addEvent" placeholder="Info" onChange={(event)=>{
              setCharityInfo(event.target.value);
          }}/>
          <br></br>
          <input class="addEvent" placeholder="Link" onChange={(event)=>{
              setCharityLink(event.target.value);
          }}/>
          <br></br>
          <DatePicker 
              showTimeInput
              dateFormat="MMMM d, yyyy"
          selected={charityDate} onChange={date=> setCharityDate(date)} />
          <br></br>
          <button onClick={createCharityEvent}>Add Charity Event</button>
          <br></br>
        </div>
      </div>
    <button class="accordionButton" onClick={charityAccButton}>Edit Charity Events</button>
      <div class="accordion" id="charityAccordion" style={{display: "none"}}>
      <h1>Charity Events</h1>
      <h4>To sign up for an event, follow the link provided in the event below.</h4>
      <ul class="eventList">
        {charEvents?.map((event, index) => (
            <li key={event.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{event.Name}</h2>
                  <input class="eventEdit" placeholder="Edit Name" onChange={(e) =>{setEditCharName(e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateCharName(event.id)}}>Edit Charity Event Name</button>
                </div>
                <div class="eventHeader" style={{justifyContent: 'spaceBetween'}}>
                  <h4 class="loc">{event.Location}</h4>
                  <input class="eventEdit" placeholder="Edit Name" onChange={(e) =>{setEditCharLoc (e.target.value)}}></input>
                  <button class="editButton" onClick={()=>{updateCharLocation(event.id)}}>Edit Charity Event Location</button>
                  <h4>
                    {new Date(event.Date.seconds * 1000 + event.Date.nanoseconds / 1000000).toDateString()}<br></br>
                    <DatePicker 
                    showTimeInput
                    dateFormat="MMMM d, yyyy"
                    selected={editCharDate} onChange={date=> setEditCharDate(date)} />
                  <button class="editButton" onClick={()=>{updateCharDate(event.id)}}>Edit Event Date</button>
                  </h4>
                </div>
                <div style={{margin: '0 auto', margin: '40px'}}>
                    <p>{event.Info}</p>
                    <input class="eventEdit" placeholder="Edit Charity Event Info" onChange={(e) =>{setEditCharInfo(e.target.value)}}></input>
                    <button class="editButton" onClick={()=>{updateCharInfo(event.id)}}>Edit Charity Event Info</button>
                </div>
                <div class="inputDiv">
                    <a href={event.Link} target="_blank" class="linkButton">
                        View Event
                    </a>
                    <input class="eventEdit" placeholder="Edit Charity Event Link" onChange={(e) =>{setEditCharLink(e.target.value)}}></input>
                    <button class="editButton" onClick={()=>{updateCharLink(event.id)}}>Edit Charity Event Link</button>
                </div>
                <button id={event.id+'deleteChar'} class="deleteEvent" onClick={() =>{deleteCharEvent(event.id)}}>Delete Charity Event</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
  </div>
  );
};
  
export default Add_Event;